const theme = {
    initialColorModeName: "light",
    config: {
        useCustomProperties: true,
    },
    colors: {
        primary: "#6b46c1",
        text: "#2d3748",
        excerp: "#465264",
        secondary: "#5f6c80",
        toggleIcon: "#2d3748",
        background: "#ffffff",
        heading: "#000000",
        divide: "#cbd5e0",
        modes: {
            dark: {
                primary: "#9f7aea",
                text: "#cbd5e0",
                excerp: "#A5B2C2",
                secondary: "#7f8ea3",
                toggleIcon: "#cbd5e0",
                background: "#1A202C",
                heading: "#ffffff",
                divide: "#2d3748",
                // muted: `#2d3748`,
            },
        },
    },
};

export default theme;
